<template>
  <v-dialog v-model="visible"
            width="700"
            @click:outside="closeDialog"
            @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">Добавить запись</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-5">
        <v-container fluid>
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <v-list>
                  <v-subheader>IOS</v-subheader>
                  <v-list-item>
                    <v-list-item-content>Всего установлено</v-list-item-content>
                    <v-list-item-content class="align-end">
                      <v-text-field
                        :rules="[(v) => v >= 0 || 'Обязательно поле']"
                        @keypress="onlyNumber"
                        v-model.number="newRecord.installationsAllIphone" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Сегодня установлено</v-list-item-content>
                    <v-list-item-content class="align-end">
                      <v-text-field
                        :rules="[(v) => v >= 0 || 'Обязательно поле']"
                        @keypress="onlyNumber"
                        v-model.number="newRecord.installationsTodayIphone" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Всего удалили</v-list-item-content>
                    <v-list-item-content class="align-end">
                      <v-text-field
                        :rules="[(v) => v >= 0 || 'Обязательно поле']"
                        @keypress="onlyNumber"
                        v-model.number="newRecord.deletedIphone" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Не удалили</v-list-item-content>
                    <v-list-item-content class="align-end">
                      <v-text-field
                        :rules="[(v) => v >= 0 || 'Обязательно поле']"
                        @keypress="onlyNumber"
                        v-model.number="newRecord.notDeletedIphone" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col>
                <v-list>
                  <v-subheader>Android</v-subheader>
                  <v-list-item>
                    <v-list-item-content>Всего установлено</v-list-item-content>
                    <v-list-item-content>
                      <v-text-field :rules="[(v) => v >= 0 || 'Обязательно поле']"
                                    @keypress="onlyNumber"
                                    v-model.number="newRecord.installationsAll" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Сегодня установлено</v-list-item-content>
                    <v-list-item-content>
                      <v-text-field :rules="[(v) => v >= 0 || 'Обязательно поле']"
                                    @keypress="onlyNumber"
                                    v-model.number="newRecord.installationsToday" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Всего удалили</v-list-item-content>
                    <v-list-item-content>
                      <v-text-field :rules="[(v) => v >= 0 || 'Обязательно поле']"
                                    @keypress="onlyNumber"
                                    v-model.number="newRecord.deletedAndroid" />
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Не удалили</v-list-item-content>
                    <v-list-item-content>
                      <v-text-field :rules="[(v) => v >= 0 || 'Обязательно поле']"
                                    @keypress="onlyNumber"
                                    v-model.number="newRecord.notDeleted" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>

            <v-row>
              <h3>Заказ</h3>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Всего заказов"
                              :rules="[(v) => v >= 0 || 'Обязательно поле']"
                              @keypress="onlyNumber"
                              v-model.number="newRecord.ordersAll" />
              </v-col>
              <v-col>
                <v-text-field label="Заказы сегодня"
                              :rules="[(v) => v >= 0 || 'Обязательно поле']"
                              @keypress="onlyNumber"
                              v-model.number="newRecord.ordersToday" />
              </v-col>
              <v-col>
                <v-text-field label="Всего попыток"
                              :rules="[(v) => v >= 0 || 'Обязательно поле']"
                              @keypress="onlyNumber"
                              v-model.number="newRecord.triesAll" />
              </v-col>
              <v-col>
                <v-text-field label="Попыток сегодня"
                              :rules="[(v) => v >= 0 || 'Обязательно поле']"
                              @keypress="onlyNumber"
                              v-model.number="newRecord.triesToday" />
              </v-col>
            </v-row>
            <v-row>
              <h3>Просмотры</h3>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Сколько людей смотрели дэшборд за последние 24 часа:"
                              :rules="[(v) => v >= 0 || 'Обязательно поле']"
                              @keypress="onlyNumber"
                              v-model.number="newRecord.views" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end pb-5">
        <v-btn
            @click="saveRecord"
            color="#007450"
            style="color: #FFFFFF; border-radius: 18px"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'NewRecord',
  props: {
    visible: {
      default: false,
    },
  },
  data() {
    return {
      valid: false,
      menu: false,
      today: new Date().toJSON(),
      newRecord: {
        installationsAllIphone: null,
        installationsTodayIphone: null,
        deletedIphone: null,
        notDeletedIphone: null,

        installationsAll: null,
        installationsToday: null,
        deletedAndroid: null,
        notDeleted: null,

        ordersAll: null,
        ordersToday: null,
        triesAll: null,
        triesToday: null,
        views: null,
      },
    };
  },
  methods: {
    onlyNumber(event) {
      const { keyCode } = event;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    closeDialog() {
      this.$emit('close');
    },
    saveRecord() {
      if (this.$refs.form.validate()) {
        this.$emit('save', this.newRecord);
      } else {
        this.$toast.warning('Заполните все поля');
      }
    },
  },
};
</script>

<style scoped>

</style>
