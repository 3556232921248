<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="data"
            :config="config"
            :loaded-options="options"
            :total-elements="totalElements"
            :total-pages="totalPages"
            @onChangeData="onChangeData"
            @handleActions="handle"
        ></crm-table-pageable-new>
      </v-col>
    </v-row>
    <new-record v-if="newRecordDialogVisible"
                :visible="newRecordDialogVisible"
                @close="handleClose"
                @save="saveRecord"/>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import NewRecord from './NewRecord.vue';

export default {
  name: 'index',
  components: {
    NewRecord,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: false, text: '#', type: 'defaultItem',
        },
        {
          value: 'created', sortable: true, text: 'Дата', type: 'defaultItem',
        },
        {
          value: 'installationsAllIphone', sortable: false, text: 'Всего установлено IOS', type: 'defaultItem',
        },
        {
          value: 'installationsTodayIphone', sortable: false, text: 'Сегодня установлено IOS', type: 'defaultItem',
        },
        {
          value: 'deletedIphone', sortable: false, text: 'Всего удалили IOS', type: 'defaultItem',
        },
        {
          value: 'notDeletedIphone', sortable: false, text: 'Не удалили IOS', type: 'defaultItem',
        },
        {
          value: 'installationsAll', sortable: false, text: 'Всего установлено Android', type: 'defaultItem',
        },
        {
          value: 'installationsToday', sortable: false, text: 'Сегодня установлено Android', type: 'defaultItem',
        },
        {
          value: 'deletedAndroid', sortable: false, text: 'Всего удалили Android', type: 'defaultItem',
        },
        {
          value: 'notDeleted', sortable: false, text: 'Не удалили Android', type: 'defaultItem',
        },
        {
          value: 'ordersAll', sortable: false, text: 'Всего заказов', type: 'defaultItem',
        },
        {
          value: 'ordersToday', sortable: false, text: 'Заказы сегодня', type: 'defaultItem',
        },
        {
          value: 'triesAll', sortable: false, text: 'Всего попыток заказов', type: 'defaultItem',
        },
        {
          value: 'triesToday', sortable: false, text: 'Всего попыток заказов сегодня', type: 'defaultItem',
        },
        {
          value: 'views', sortable: false, text: 'Кол-во просмотров', type: 'defaultItem',
        },
        // {
        //   text: 'Действие',
        //   value: 'actions',
        //   type: 'actions',
        //   sortable: false,
        //   child: [
        //     {
        //       label: 'Удалить',
        //       event: 'delete',
        //       icon: 'mdi-delete',
        //       visible: true,
        //     },
        //   ],
        // },
      ],
      data: [],
      newRecordDialogVisible: false,
      config: {
        add: true,
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/crm/statistics/mobile-app',
      options: {
        page: 1,
        size: 10,
        sort: 'created,desc',
      },

    };
  },

  created() {
    this.onChangeData(this.$route.query);
  },

  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getTableData();
    },

    async getTableData() {
      const params = this.$route.query;
      const formattedUrl = this.url;
      this.$loading(true);
      this.$newCrmService.get(formattedUrl, {
        params: {
          pageNumber: params.page,
          pageSize: params.size,
          Sorting: params.sort,
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / (params.size || 1));
        this.data = response.content.map((el) => ({
          ...el,
          created: this.getFormattedDate(el.created),
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },

    saveRecord(newRecord) {
      this.$loading(true);
      this.$newCrmService.post(this.url, {
        ...newRecord,
      }).then(() => {
        this.handleClose();
      }).finally(() => {
        this.$loading(false);
      });
    },

    handle(element) {
      switch (element.type) {
        case 'create':
          this.newRecordDialogVisible = true;
          break;
        case 'delete':
          this.deleteItem(element);
          break;
        default:
          break;
      }
    },

    deleteItem(element) {
      element.event.stopPropagation();
      this.$store.dispatch('tools/showConfirm', {
        title: 'Подтвердите',
        text: 'Вы действительно хотите удалить?',
      }).then(() => {
        this.$api.delete(`${this.url}/${element.item.id}`)
          .then(() => { this.onChangeData(this.$route.query); })
          .catch((e) => {
            this.$store.dispatch('tools/setSnackbar', {
              type: 'error',
              message: e.response.data.error || 'Ошибка!',
            });
          });
      }).catch(() => {});
    },

    handleClose() {
      this.newRecordDialogVisible = false;
      this.onChangeData(this.$route.query);
    },

    getFormattedDate(date) {
      if (!this.$moment(date).isValid()) return 'Нет данных';
      return this.$moment(date).format('DD.MM.YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss">
</style>
